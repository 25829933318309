@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

* {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #282c34;
  /* min-height: 100vh; */
  /* min-width: 100vw; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
